import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as productActions from '../../actions/product';
import * as userActions from '../../actions/account';
import Loader from '../../components/Utility/Loader';
import getSymbolFromCurrency from 'currency-symbol-map';
import axios from './../../axios';
import SearchInput from './../../components/SearchInput';
import * as actionType from '../../constants/actionTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMobileAlt,
  faLaptop,
  faTshirt,
  faShoePrints,
  faGem,
  faHeartbeat,
  faHome,
  faUtensils,
  faBaby,
  faFutbol,
  faTools,
  faClock
} from '@fortawesome/free-solid-svg-icons';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      selectedCategory: null,
      search_loader: false,
      website: null,
      zero_product: false,
      currentPage: 1,
      product_details: [],
      header_search_loader: false,
      ConversionCurrency: getSymbolFromCurrency('NGN'),
      currencyRate: null,
      searchKeyword: null,
      searchInput: '',
      pages: [1],
      selectedKeywords: [],
      showScrollTop: false
    };

    this.subCategoryMap = {
      phones: [
        { name: 'iphones', display: 'iPhone', icon: faMobileAlt },
        { name: 'samsung galaxy', display: 'Samsung', icon: faMobileAlt },
        { name: 'android', display: 'Android', icon: faMobileAlt },
        { name: 'xiaomi phones', display: 'Xiaomi', icon: faMobileAlt },
        { name: 'oppo', display: 'Oppo', icon: faMobileAlt },
        { name: 'vivo', display: 'Vivo', icon: faMobileAlt },
        { name: 'huawei', display: 'Huawei', icon: faMobileAlt },
        { name: 'nokia', display: 'Nokia', icon: faMobileAlt },
        { name: 'realme', display: 'Realme', icon: faMobileAlt }
      ],
      electronics: [
        { name: 'tv', display: 'TVs', icon: faLaptop },
        { name: 'laptop', display: 'Laptops', icon: faLaptop },
        { name: 'headphones', display: 'Headphones', icon: faLaptop },
        { name: 'speakers', display: 'Speakers', icon: faLaptop },
        { name: 'cameras', display: 'Cameras', icon: faLaptop },
        { name: 'tablets', display: 'Tablets', icon: faLaptop },
        { name: 'smartwatches', display: 'Smartwatches', icon: faLaptop },
        { name: 'printers', display: 'Printers', icon: faLaptop },
        { name: 'consoles', display: 'Gaming Consoles', icon: faLaptop }
      ],
      fashion: [
        { name: 'shirts', display: 'Shirts', icon: faTshirt },
        { name: 'dresses', display: 'Dresses', icon: faTshirt },
        { name: 'jeans', display: 'Jeans', icon: faTshirt },
        { name: 'jackets', display: 'Jackets', icon: faTshirt },
        { name: 'skirts', display: 'Skirts', icon: faTshirt },
        { name: 'trousers', display: 'Trousers', icon: faTshirt },
        { name: 'sweaters', display: 'Sweaters', icon: faTshirt },
        { name: 'tshirts', display: 'T-Shirts', icon: faTshirt },
        { name: 'hoodies', display: 'Hoodies', icon: faTshirt }
      ],
      footwear: [
        { name: 'sneakers', display: 'Sneakers', icon: faShoePrints },
        { name: 'boots', display: 'Boots', icon: faShoePrints },
        { name: 'sandals', display: 'Sandals', icon: faShoePrints },
        { name: 'heels', display: 'Heels', icon: faShoePrints },
        { name: 'flats', display: 'Flats', icon: faShoePrints },
        { name: 'slippers', display: 'Slippers', icon: faShoePrints },
        { name: 'loafers', display: 'Loafers', icon: faShoePrints },
        { name: 'running', display: 'Running Shoes', icon: faShoePrints },
        { name: 'casual', display: 'Casual Shoes', icon: faShoePrints }
      ],
      beauty: [
        { name: 'makeup', display: 'Makeup', icon: faGem },
        { name: 'skincare', display: 'Skincare', icon: faGem },
        { name: 'perfume', display: 'Perfumes', icon: faGem },
        { name: 'haircare', display: 'Haircare', icon: faGem },
        { name: 'nailcare', display: 'Nailcare', icon: faGem },
        { name: 'lipstick', display: 'Lipsticks', icon: faGem },
        { name: 'foundation', display: 'Foundations', icon: faGem },
        { name: 'mascara', display: 'Mascaras', icon: faGem },
        { name: 'eyeshadow', display: 'Eyeshadows', icon: faGem }
      ],
      health: [
        { name: 'vitamins', display: 'Vitamins', icon: faHeartbeat },
        { name: 'supplements', display: 'Supplements', icon: faHeartbeat },
        { name: 'firstaid', display: 'First Aid', icon: faHeartbeat },
        { name: 'thermometers', display: 'Thermometers', icon: faHeartbeat },
        { name: 'masks', display: 'Face Masks', icon: faHeartbeat },
        { name: 'bandages', display: 'Bandages', icon: faHeartbeat },
        { name: 'monitors', display: 'Health Monitors', icon: faHeartbeat },
        { name: 'painrelief', display: 'Pain Relief', icon: faHeartbeat },
        { name: 'fitness', display: 'Fitness Gear', icon: faHeartbeat }
      ],
      home: [
        { name: 'furniture', display: 'Furniture', icon: faHome },
        { name: 'decor', display: 'Home Decor', icon: faHome },
        { name: 'bedding', display: 'Bedding', icon: faHome },
        { name: 'lighting', display: 'Lighting', icon: faHome },
        { name: 'curtains', display: 'Curtains', icon: faHome },
        { name: 'rugs', display: 'Rugs', icon: faHome },
        { name: 'storage', display: 'Storage', icon: faHome },
        { name: 'mirrors', display: 'Mirrors', icon: faHome },
        { name: 'clocks', display: 'Clocks', icon: faHome }
      ],
      kitchen: [
        { name: 'cookware', display: 'Cookware', icon: faUtensils },
        { name: 'utensils', display: 'Utensils', icon: faUtensils },
        { name: 'appliances', display: 'Appliances', icon: faUtensils },
        { name: 'cutlery', display: 'Cutlery', icon: faUtensils },
        { name: 'bakeware', display: 'Bakeware', icon: faUtensils },
        { name: 'storage', display: 'Food Storage', icon: faUtensils },
        { name: 'kettles', display: 'Kettles', icon: faUtensils },
        { name: 'blenders', display: 'Blenders', icon: faUtensils },
        { name: 'coffee', display: 'Coffee Makers', icon: faUtensils }
      ],
      baby: [
        { name: 'diapers', display: 'Diapers', icon: faBaby },
        { name: 'clothing', display: 'Clothing', icon: faBaby },
        { name: 'toys', display: 'Toys', icon: faBaby },
        { name: 'strollers', display: 'Strollers', icon: faBaby },
        { name: 'feeding', display: 'Feeding', icon: faBaby },
        { name: 'cribs', display: 'Cribs', icon: faBaby },
        { name: 'carseats', display: 'Car Seats', icon: faBaby },
        { name: 'bath', display: 'Bath Items', icon: faBaby },
        { name: 'monitors', display: 'Baby Monitors', icon: faBaby }
      ],
      sports: [
        { name: 'footwear', display: 'Sports Footwear', icon: faFutbol },
        { name: 'apparel', display: 'Sports Apparel', icon: faFutbol },
        { name: 'equipment', display: 'Equipment', icon: faFutbol },
        { name: 'bikes', display: 'Bicycles', icon: faFutbol },
        { name: 'weights', display: 'Weights', icon: faFutbol },
        { name: 'yoga', display: 'Yoga Gear', icon: faFutbol },
        { name: 'balls', display: 'Sports Balls', icon: faFutbol },
        { name: 'rackets', display: 'Rackets', icon: faFutbol },
        { name: 'fitness', display: 'Fitness Trackers', icon: faFutbol }
      ],
      tools: [
        { name: 'drills', display: 'Drills', icon: faTools },
        { name: 'saws', display: 'Saws', icon: faTools },
        { name: 'hammers', display: 'Hammers', icon: faTools },
        { name: 'screwdrivers', display: 'Screwdrivers', icon: faTools },
        { name: 'wrenches', display: 'Wrenches', icon: faTools },
        { name: 'toolkits', display: 'Toolkits', icon: faTools },
        { name: 'ladders', display: 'Ladders', icon: faTools },
        { name: 'measuring', display: 'Measuring Tools', icon: faTools },
        { name: 'powertools', display: 'Power Tools', icon: faTools }
      ],
      watches: [
        { name: 'smartwatches', display: 'Smartwatches', icon: faClock },
        { name: 'analog', display: 'Analog Watches', icon: faClock },
        { name: 'digital', display: 'Digital Watches', icon: faClock },
        { name: 'luxury', display: 'Luxury Watches', icon: faClock },
        { name: 'sports', display: 'Sports Watches', icon: faClock },
        { name: 'casual', display: 'Casual Watches', icon: faClock },
        { name: 'fashion', display: 'Fashion Watches', icon: faClock },
        { name: 'chronograph', display: 'Chronographs', icon: faClock },
        { name: 'fitness', display: 'Fitness Watches', icon: faClock }
      ]
    };
  }

  shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  getRandomKeywords = (keywords, count = 7) => {
    const shuffled = this.shuffleArray(keywords);
    return shuffled.slice(0, count);
  };

  fetchAllProducts = async (keywords) => {
    this.setState({ search_loader: true });
    const productPromises = keywords.map((keyword) =>
      this.props.onGetProduct(keyword).catch((error) => {
        console.error(`Error fetching products for ${keyword}:`, error);
        return { data: { response: { data: { data: [] } } } };
      })
    );

    try {
      const responses = await Promise.all(productPromises);
      const allProducts = responses
        .flatMap((response) => response?.data?.response?.data?.data || [])
        .filter(
          (product, index, self) =>
            product?.item_id &&
            index === self.findIndex((p) => p.item_id === product.item_id)
        );

      const shuffledProducts = allProducts.sort(() => Math.random() - 0.5);
      const limitedProducts = shuffledProducts.slice(0, 120);

      this.setState({
        product_details: limitedProducts,
        zero_product: limitedProducts.length === 0,
        search_loader: false
      });
    } catch (error) {
      console.error('Error fetching products:', error);
      this.setState({
        search_loader: false,
        zero_product: true
      });
      toast.error('An error occurred while fetching products', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  fetchCurrencyRate = async () => {
    try {
      const response = await fetch(
        `${actionType.environment_url}/GetCurrencyRate`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch currency rate JSON');
      }
      const data = await response.json();
      const rate = data.data.currency_rate;
      localStorage.setItem('currencyRate', rate);
      this.setState({ currencyRate: rate });
      console.log('Currency Rate Fetched from JSON:', rate);
    } catch (error) {
      console.error('Error fetching currency rate:', error.message);
      const defaultRate = 1;
      localStorage.setItem('currencyRate', defaultRate);
      this.setState({ currencyRate: defaultRate });
      toast.error(
        `Failed to fetch currency rate: ${error.message}, using default`,
        {
          position: toast.POSITION.TOP_RIGHT
        }
      );
    }
  };

  async componentDidMount() {
    await this.fetchCurrencyRate();
    this.setState({ website: 'http://www.taobao.com/' });

    const topNigeriaEcommerceSearches = [
      'smartphones',
      'electronics',
      'fashion',
      'footwear',
      'beauty',
      'health',
      'home',
      'kitchen',
      'baby',
      'sports',
      'tools',
      'watches'
    ];

    let selectedKeywords = localStorage.getItem('selectedKeywords');
    if (!selectedKeywords) {
      selectedKeywords = this.getRandomKeywords(topNigeriaEcommerceSearches);
      localStorage.setItem(
        'selectedKeywords',
        JSON.stringify(selectedKeywords)
      );
    } else {
      selectedKeywords = JSON.parse(selectedKeywords);
    }

    console.log('Selected Keywords:', selectedKeywords);
    this.setState({ selectedKeywords });
    await this.fetchAllProducts(selectedKeywords);

    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 300) {
      this.setState({ showScrollTop: true });
    } else {
      this.setState({ showScrollTop: false });
    }
  };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  SearchHandler = async (value) => {
    if (!value) {
      toast.error('Keyword is required to search', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    const keyword = value;
    this.setState({ search_loader: true });

    try {
      const response = await axios.get(`/item_search?keyword=${keyword}`);
      console.log('SearchHandler Response:', response);
      if (response.data && response.data.response?.data?.data) {
        this.setState({
          is_redirect: true,
          redirect_url: `/product-list/search?search=${keyword}`,
          redirect_state: { results: response.data.response.data.data }
        });
      } else {
        toast.error('No Result Found', { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      console.error('Error occurred during search:', error);
      toast.error('An error occurred during search', {
        position: toast.POSITION.TOP_RIGHT
      });
    } finally {
      this.setState({ search_loader: false });
    }
  };

  handleCategoryClick = (categoryName) => {
    this.setState((prevState) => ({
      selectedCategory:
        prevState.selectedCategory === categoryName ? null : categoryName
    }));
  };

  render() {
    const {
      product_details,
      zero_product,
      currencyRate,
      ConversionCurrency,
      searchKeyword,
      showScrollTop,
      selectedCategory
    } = this.state;

    const currentPageData = product_details;

    const categoryBlocks = [
      {
        name: 'phones',
        image:
          'https://images.pexels.com/photos/788946/pexels-photo-788946.jpeg?auto=compress&cs=tinysrgb&w=300'
      },
      {
        name: 'electronics',
        image:
          'https://images.pexels.com/photos/356056/pexels-photo-356056.jpeg?auto=compress&cs=tinysrgb&w=300'
      },
      {
        name: 'fashion',
        image:
          'https://images.pexels.com/photos/996329/pexels-photo-996329.jpeg?auto=compress&cs=tinysrgb&w=300'
      },
      {
        name: 'footwear',
        image:
          'https://images.pexels.com/photos/1598505/pexels-photo-1598505.jpeg?auto=compress&cs=tinysrgb&w=300'
      },
      {
        name: 'beauty',
        image:
          'https://images.pexels.com/photos/3738076/pexels-photo-3738076.jpeg?auto=compress&cs=tinysrgb&w=300'
      },
      {
        name: 'health',
        image:
          'https://images.pexels.com/photos/3683106/pexels-photo-3683106.jpeg?auto=compress&cs=tinysrgb&w=300'
      },
      {
        name: 'home',
        image:
          'https://images.pexels.com/photos/276583/pexels-photo-276583.jpeg?auto=compress&cs=tinysrgb&w=300'
      },
      {
        name: 'kitchen',
        image:
          'https://images.pexels.com/photos/4350222/pexels-photo-4350222.jpeg?auto=compress&cs=tinysrgb&w=300'
      },
      {
        name: 'baby',
        image:
          'https://images.pexels.com/photos/5052879/pexels-photo-5052879.jpeg?auto=compress&cs=tinysrgb&w=300'
      },
      {
        name: 'sports',
        image:
          'https://images.pexels.com/photos/209977/pexels-photo-209977.jpeg?auto=compress&cs=tinysrgb&w=300'
      },
      {
        name: 'tools',
        image:
          'https://images.pexels.com/photos/209235/pexels-photo-209235.jpeg?auto=compress&cs=tinysrgb&w=300'
      },
      {
        name: 'watches',
        image:
          'https://images.pexels.com/photos/190819/pexels-photo-190819.jpeg?auto=compress&cs=tinysrgb&w=300'
      }
    ];

    return (
      <React.Fragment>
        {this.state.is_redirect ? (
          <Navigate
            to={this.state.redirect_url}
            state={this.state.redirect_state}
          />
        ) : null}
        {this.state.search_loader || this.props.loader ? <Loader /> : null}

        <div className="main-content">
          <div className="category-sliderXXX">
            <div className="container-fluid">
              <SearchInput />
              <div className="row mb-4">
                <div className="col-12">
                  <div className="category-grid">
                    {categoryBlocks.map((category, index) => (
                      <div
                        key={index}
                        className={`category-item ${
                          selectedCategory === category.name ? 'active' : ''
                        }`}
                      >
                        <button
                          className="category-button"
                          onClick={() =>
                            this.handleCategoryClick(category.name)
                          }
                        >
                          <img
                            src={category.image}
                            alt={category.name}
                            className="category-image"
                            onError={(e) => {
                              e.target.src =
                                'https://via.placeholder.com/200?text=Image+Not+Found';
                            }}
                          />
                          <div className="category-name">{category.name}</div>
                        </button>
                        {selectedCategory === category.name && (
                          <div className="subcategory-dropdown">
                            {this.subCategoryMap[category.name].map(
                              (subCat, subIndex) => (
                                <button
                                  key={subIndex}
                                  className="subcategory-button"
                                  onClick={() =>
                                    this.SearchHandler(subCat.name)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={subCat.icon}
                                    className="subcategory-icon"
                                  />
                                  <span>{subCat.display}</span>
                                </button>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="category-sec">
                  <div className="container-fluid">
                    <div className="row gx-3" id="currentPageData">
                      {zero_product &&
                        'NO RESULT FOUND PLEASE TRY AGAIN WITH OTHER KEYWORDS'}
                      {currentPageData.map((item, product_key) => (
                        <div
                          className="col-lg-2 col-md-4 col-sm-6"
                          key={product_key}
                        >
                          <div className="product-card">
                            <Link
                              to={`/product-detail/search?${item?.item_id}`}
                              state={{ product_detail: item, searchKeyword }}
                              className="product-card__link"
                            >
                              <div className="product-card__image-container">
                                <img
                                  src={item?.main_image_url}
                                  alt={item?.title || 'Product Image'}
                                  className="product-card__image"
                                />
                              </div>
                              <div className="product-card__content">
                                <h2 className="product-card__title">
                                  {item?.multi_language_info.title}
                                </h2>
                                <div className="product-card__price">
                                  <span className="card__amount">
                                    {ConversionCurrency}
                                    {currencyRate
                                      ? parseFloat(
                                          item?.price * currencyRate
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })
                                      : 'Loading...'}
                                  </span>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />

          {showScrollTop && (
            <button onClick={this.scrollToTop} className="scroll-to-top">
              <span>↑</span>
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    productData: state.Product.productData,
    loader: state.Auth.loader
  };
}

const mapDispatchToProps = {
  onGetProduct: productActions.Getproduct,
  onCart: userActions.GetCart,
  getProduct: productActions.Getproduct,
  getCategoriesList: productActions.getCategoriesList
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(Home);
