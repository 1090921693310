import React, { Component } from 'react';
import { connect } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import defaultImage from '../../../assets/static images/Default-welcomer.png';
import * as userActions from '../../../actions/index';
import OrderStatusModal from './modals/orderStatus';
import Loader from '../../../components/Utility/Loader.js';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import Logout from '../../../components/SuperAdmin/Logout.js';

class ViewOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order_items: [],
      productDeatil: [],
      customer: '',
      statusHistory: [],
      subData: '',
      updateStatus_modal: false,
      inputFieldInvalid: '',
      homeSection: {
        overflow: 'hidden'
      },
      isloading: true,
      productImageDiv: {
        height: '100px',
        width: '100px'
      },
      productImage: {
        height: '100%',
        width: '100%',
        objectFit: 'cover'
      },
      headerCard: {
        /* width: 100%; */
        padding: '0px 22px',
        margin: '0 20px',
        borderRadius: '12px',
        boxShadow: '0 5px 10px rgba(0, 0, 0, 0.1)'
      },
      statusbar: {
        width: '0%'
      }
    };
  }

  componentDidMount() {
    let search = this.props.location;
    const id = search.pathname.replace('/super-admin-view-order/', '');
    const form_data = new FormData();
    form_data.append('id', id);
    this.props.viewOrder(form_data).then((response) => {
      if (response.success === true) {
        const { orders, customer, statusHistory } = response.data;
        const productJson = JSON.parse(orders[0].order_items[0].productJson);
        this.setState({
          order_items: orders[0].order_items
        })
        if (orders.length > 0) {
          const productDeatil = [
            {
              product_name: productJson.product_name,
              product_avatar: productJson.product_avatar,
              price: productJson.price,
              total_price: orders[0].totalprice,
              quantity: orders[0].order_items[0].quantity,
              product_requirement: productJson.product_requirement
            }
          ];
          const subData = {
            id: orders[0].id,
            subtotal_price: orders[0].subtotal_price
              ? orders[0].subtotal_price
              : orders[0].totalprice,
            discount_amount: orders[0].discount_amount,
            discount_coupon: orders[0].discount_coupon,
            shipping_charge: orders[0].shipping_charge,
            totalprice: orders[0].totalprice,
            billing_address: orders[0].billing_address,
            billing_country: orders[0].billing_country,
            billing_email: orders[0].billing_email,
            billing_first_name: orders[0].billing_first_name,
            billing_last_name: orders[0].billing_last_name,
            billing_state: orders[0].billing_state,
            billing_zip: orders[0].billing_zip,
            shipping_service: orders[0].shipping_service,
            shipping_method: orders[0].shipping_method,
            status: orders[0].status
          };
          this.setState({
            productDeatil,
            customer,
            subData,
            statusHistory
          });
        }
        // let width = orders[0].currentLocation * 100 / orders[0].totalLocations;
        let width = (statusHistory.length * 100) / 7;

        this.setState({
          statusbar: { width: `${width}%` }
        });
        setTimeout(() => {
          this.setState({
            isloading: false
          });
        }, 500);
      } else {
        console.log('error');
      }
    });
  }

  SubmitHandler = (event) => {
    event.preventDefault();
    const form_data = new FormData();
    let search = this.props.location;
    const id = search.pathname.replace('/super-admin-view-order/', '');
    form_data.append('id', id);
    if (event.target[0].value === 'location' && !event.target[1].value.trim()) {
      this.setState({
        inputFieldInvalid: true
      });
    } else {
      this.setState({
        inputFieldInvalid: false
      });
    }
    form_data.append('status', event.target[0].value);
    form_data.append('location', event.target[1].value);
    //this.props.ForgotPassword(form_data);

    if (this.state.inputFieldInvalid === false) {
      this.setState({
        productDeatil: [],
        customer: '',
        updateStatus_modal: false
      });
      this.props
        .updateTrackingStatus(form_data)
        .then((response) => {
          this.setState({
            isloading: true
          });
          this.props
            .viewOrder(form_data)
            .then((response) => {
              if (response.success === true) {
                let { orders, customer, statusHistory } = response.data;
                if (orders.length > 0) {
                  console.log(orders[0]);
                  const productDeatil = [
                    {
                      product_name:
                        orders[0].order_items[0].productJson.item.title,
                      product_avatar:
                        orders[0].order_items[0].productJson.item.pic_url,
                      price:
                        orders[0].order_items[0].productJson.item.orginal_price,
                      total_price: orders[0].totalprice,
                      quantity: orders[0].order_items[0].quantity
                    }
                  ];
                  const subData = {
                    id: orders[0].id,
                    subtotal_price: orders[0].subtotal_price
                      ? orders[0].subtotal_price
                      : orders[0].totalprice,
                    discount_amount: orders[0].discount_amount,
                    discount_coupon: orders[0].discount_coupon,
                    shipping_charge: orders[0].shipping_charge,
                    totalprice: orders[0].totalprice,
                    billing_address: orders[0].billing_address,
                    billing_country: orders[0].billing_country,
                    billing_email: orders[0].billing_email,
                    billing_first_name: orders[0].billing_first_name,
                    billing_last_name: orders[0].billing_last_name,
                    billing_state: orders[0].billing_state,
                    billing_zip: orders[0].billing_zip,
                    shipping_service: orders[0].shipping_service,
                    shipping_method: orders[0].shipping_method
                  };
                  this.setState({
                    productDeatil,
                    customer,
                    subData,
                    statusHistory
                  });
                }
                // let width = orders[0].currentLocation * 100 / orders[0].totalLocations;
                // this.setState({
                //     statusbar: { width: `${width}%` }
                // })
                let width = (statusHistory.length * 100) / 7;
                this.setState({
                  statusbar: { width: `${width}%` }
                });
                setTimeout(() => {
                  this.setState({
                    isloading: false
                  });
                }, 500);
              } else {
                console.log('error');
              }
            })
            .finally(() => {
              this.setState({
                isloading: false
              });
            });
        })
        .then((error) => {
          console.log(error);
        });
    }
  };

  deliveryStatusBar() {
    let { statusHistory } = this.state;

    if (statusHistory.length > 0) {
      if (statusHistory[statusHistory.length - 1].status_type !== 'delivered') {
        console.log('in');
        let count = 7 - statusHistory.length;
        const html = [];
        for (let i = 1; i <= count; i++) {
          html.push(
            <div
              key={i}
              className={
                count - i !== 0
                  ? 'node n1 done nConfirm1'
                  : 'node n0 done nConfirm0'
              }
            >
              <div
                className={
                  count - i !== 0
                    ? 'main m1 nConfirm1'
                    : 'main done m0 done nConfirm0'
                }
              ></div>
              <span
                className={
                  count - i !== 0
                    ? 'text t1 nConfirm1'
                    : 'text t0 done nConfirm0'
                }
              >
                {count - i !== 0 ? '?' : 'Delivered'}
              </span>
            </div>
          );
        }
        return html;
      } else {
        this.setState({
          statusbar: { width: `100%` }
        });
      }
    } else {
      const html = [];
      for (let i = 0; i < 7; i++) {
        html.push(
          <div
            key={i}
            className={
              i !== 6 ? 'node n1 done nConfirm1' : 'node n0 done nConfirm0'
            }
          >
            <div
              className={
                i !== 6 ? 'main m1 nConfirm1' : 'main done m0 done nConfirm0'
              }
            ></div>
            <span
              className={
                i !== 6 ? 'text t1 nConfirm1' : 'text t0 done nConfirm0'
              }
            >
              {i !== 6 ? '?' : 'Delivered'}
            </span>
          </div>
        );
      }
      return html;
    }
  }

  CancelOrder(event, id) {
    event.preventDefault();
    const form_data = new FormData();
    form_data.append('id', id);
    form_data.append('status', 'cancelled');
    this.props.cancelOrder(form_data).then((response) => {
      this.setState({
        isloading: true
      });
      if (response.success === true) {
        let search = this.props.location;
        const id = search.pathname.replace('/super-admin-view-order/', '');
        const form_data = new FormData();
        form_data.append('id', id);
        this.props.viewOrder(form_data).then((response) => {
          console.log(response);
          if (response.success === true) {
            const { orders, customer, statusHistory } = response.data;
            // console.log(orders[0].order_items[0].productJson.item)
            if (orders.length > 0) {
              const productDeatil = [
                {
                  product_name: orders[0].order_items[0].productJson.item.title,
                  product_avatar:
                    orders[0].order_items[0].productJson.item.pic_url,
                  price:
                    orders[0].order_items[0].productJson.item.orginal_price,
                  total_price: orders[0].totalprice,
                  quantity: orders[0].order_items[0].quantity
                }
              ];
              const subData = {
                id: orders[0].id,
                subtotal_price: orders[0].subtotal_price
                  ? orders[0].subtotal_price
                  : orders[0].totalprice,
                discount_amount: orders[0].discount_amount,
                discount_coupon: orders[0].discount_coupon,
                shipping_charge: orders[0].shipping_charge,
                totalprice: orders[0].totalprice,
                billing_address: orders[0].billing_address,
                billing_country: orders[0].billing_country,
                billing_email: orders[0].billing_email,
                billing_first_name: orders[0].billing_first_name,
                billing_last_name: orders[0].billing_last_name,
                billing_state: orders[0].billing_state,
                billing_zip: orders[0].billing_zip,
                shipping_service: orders[0].shipping_service,
                shipping_method: orders[0].shipping_method,
                status: orders[0].status
              };
              this.setState({
                productDeatil,
                customer,
                subData,
                statusHistory
              });
            }
            // let width = orders[0].currentLocation * 100 / orders[0].totalLocations;
            let width = (statusHistory.length * 100) / 7;

            this.setState({
              statusbar: { width: `${width}%` }
            });
            setTimeout(() => {
              this.setState({
                isloading: false
              });
            }, 500);
          } else {
            console.log('error');
          }
        });

        // setTimeout(() => {
        //     this.setState({
        //         isloading: false
        //     })
        // }, 500);
      } else {
        console.log('error');
      }
    });
  }
  render() {
    const { productDeatil, customer, statusHistory, subData, isloading, order_items } =
      this.state;
    console.log('productDeatil', productDeatil);
    return (
      <>
        <React.Fragment>
          {isloading === true ? <Loader /> : ''}

          {this.state.updateStatus_modal && (
            <OrderStatusModal
              SubmitHandler={this.SubmitHandler}
              orderStatus_modal={this.state.updateStatus_modal}
              inputFieldInvalid={this.state.inputFieldInvalid}
              ForgotPassword={(form_data) => this.ForgotPassword(form_data)}
              updateStatus_modal_action={(modal_action) => {
                this.setState({
                  updateStatus_modal: modal_action
                });
              }}
            />
          )}
          <section className="home-section" style={this.state.homeSection}>
            <nav>
              <div className="sidebar-button">
                <i className="bx bx-menu sidebarBtn"></i>
                <span className="dashboard">Order</span>
              </div>
              <div>
                <Logout />
              </div>
            </nav>
            <div className="home-content">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card" style={this.state.headerCard}>
                    <div className="card-header bg-white">
                      <div className="d-flex align-items-center">
                        <h5 className="card-title flex-grow-1 mb-0">
                          Order #{subData && subData.id ? subData.id : ''}
                        </h5>
                        {/* <div className="flex-shrink-0">
                                                    <a href="apps-invoices-details.html"
                                                        className="btn btn-success btn-sm"><i
                                                            className="ri-download-2-fill align-middle mr-1"></i>
                                                        Invoice</a>
                                                </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                {subData && subData.status !== 'cancelled' ? (
                  <div className="row mt-5 mb-5">
                    <div className="col-xl-12">
                      <div className="mainWrapper m-auto">
                        <div className="statusBar">
                          <span
                            className="pBar"
                            style={this.state.statusbar}
                          ></span>
                          <div className="node n0 done nConfirm0">
                            <div className="main done m0 done nConfirm0"></div>
                            <span className="text t0 done nConfirm0 text-nowrap">
                              Order Placed
                            </span>
                          </div>
                          {statusHistory.map((item, index) => (
                            <div
                              className={
                                item.status_type === 'location'
                                  ? 'node n1 done nConfirm1'
                                  : 'node n0 done nConfirm0'
                              }
                              key={index}
                            >
                              <div
                                className={
                                  item.status_type === 'location'
                                    ? 'main m1 nConfirm1'
                                    : 'main done m0 done nConfirm0'
                                }
                              ></div>
                              <span
                                className={
                                  item.status_type === 'location'
                                    ? 'text t1 nConfirm1'
                                    : 'text t0 done nConfirm0'
                                }
                              >
                                {item.status_type === 'location'
                                  ? 'shipped'
                                  : 'Delivered'}
                              </span>
                            </div>
                          ))}
                          {this.deliveryStatusBar()}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  subData.status === 'cancelled' && (
                    <div className="row mt-5 mb-5">
                      <div className="col-xl-12">
                        <div className="mainWrapper m-auto">
                          <div className="statusBar">
                            <span
                              className="pBar"
                              style={this.state.statusbar}
                            ></span>

                            {/* Added cancelled status visualization */}
                            <div className="main done m0 done nConfirm0">
                              <div className="main cancelled"></div>
                              <span className="text cancelled">
                                Order Cancelled
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}

                <div className="row mt-3 ms-4">
                  <div className="col-xl-8">
                    <div className="card h-auto">
                      <div className="card-body">
                        <div className="table-responsive table-card">
                          <table className="table table-nowrap align-middle table-borderless mb-0">
                            <thead className="table-light text-muted">
                              <tr>
                                <th scope="col">
                                  <span className="profile-desc-text ">
                                    Product Details{' '}
                                  </span>
                                </th>
                                <th scope="col">
                                  <span className="profile-desc-text ">
                                    Item Price{' '}
                                  </span>
                                </th>
                                <th scope="col">
                                  <span className="profile-desc-text ">
                                    Quantity
                                  </span>
                                </th>
                                {/* <th scope="col"><span className="profile-desc-text ">Rating</span></th> */}
                                <th scope="col" className="text-end">
                                  <span className="profile-desc-text ">
                                    Total Amount
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {productDeatil.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <div className="d-flex">
                                        <div
                                          style={this.state.productImageDiv}
                                          className="flex-shrink-0 avatar-md bg-light rounded p-1"
                                        >
                                          <img
                                            src={item.product_avatar}
                                            style={this.state.productImage}
                                            alt=""
                                            className="img-fluid d-block"
                                          />
                                        </div>
                                        <div className="flex-grow-1 ms-3 text-start">
                                          <h5 className="fs-14">
                                            <div className="text-body">
                                              {item.product_name}
                                            </div>
                                          </h5>
                                          {/* <p className="text-start mb-0">
                                                                                        Color:
                                                                                        <span className="fw-medium">Pink</span>
                                                                                    </p>
                                                                                    <p className="text-start mb-0">
                                                                                        Size:
                                                                                        <span className="fw-medium">M</span></p> */}
                                        </div>
                                      </div>
                                    </td>

                                    <td>${item.price}</td>
                                    <td>{item.quantity}</td>
                                    {/* <td>
                                                                            <div className="text-warning fs-15">
                                                                                <i className="fa fa-solid fa-star star-ratings"></i>
                                                                                <i className="fa fa-solid fa-star star-ratings"></i>
                                                                                <i className="fa fa-solid fa-star star-ratings"></i>
                                                                                <i className="fa fa-solid fa-star star-ratings"></i>
                                                                                <i className="fa fa-solid fa-star-half star-ratings"></i>
                                                                            </div>
                                                                        </td> */}
                                    <td className="fw-medium text-end">
                                      ${item.total_price}
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr className="border-top border-top-dashed">
                                <td colSpan="3"></td>
                                <td colSpan="2" className="fw-medium p-0">
                                  <table className="table table-borderless mb-0">
                                    <tbody>
                                      <tr>
                                        <td>Sub Total :</td>
                                        <td className="text-end">
                                          ${subData.subtotal_price}
                                        </td>
                                      </tr>
                                      {subData.discount_amount !== null ? (
                                        <tr>
                                          <td>
                                            Discount{' '}
                                            <span className="text-muted">
                                              (Shiprofly20)
                                            </span>
                                            :{' '}
                                          </td>
                                          <td className="text-end">
                                            -${subData.discount_amount}
                                          </td>
                                        </tr>
                                      ) : (
                                        <tr>
                                          <td>
                                            Discount{' '}
                                            <span className="text-muted"></span>
                                            :{' '}
                                          </td>
                                          <td className="text-end">-$0</td>
                                        </tr>
                                      )}
                                      {subData &&
                                      subData.shipping_charge !== undefined ? (
                                        <tr>
                                          <td>Shipping Charge :</td>
                                          <td className="text-end">
                                            ${subData.shipping_charge}
                                          </td>
                                        </tr>
                                      ) : (
                                        <tr>
                                          <td>Shipping Charge :</td>
                                          <td className="text-end">$0</td>
                                        </tr>
                                      )}
                                      {subData &&
                                      subData.totalprice !== undefined ? (
                                        <tr className="border-top border-top-dashed">
                                          <th scope="row">Total (USD) :</th>
                                          <th className="text-end">
                                            ${subData.totalprice}
                                          </th>
                                        </tr>
                                      ) : (
                                        <tr className="border-top border-top-dashed">
                                          <th scope="row">Total (USD) :</th>
                                          <th className="text-end">$0</th>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {subData.status !== 'cancelled' ? (
                      <div className="card mt-3 mb-3 h-auto">
                        <div className="card-header">
                          <div className="d-sm-flex align-items-center">
                            <h5 className="card-title flex-grow-1 mb-0">
                              Order Status
                            </h5>
                            {subData.status !== 'delivered' ? (
                              <div className="flex-shrink-0 mt-2 mt-sm-0">
                                <button
                                  data-bs-toggle="modal"
                                  data-bs-target="#staticBackdrop"
                                  type="button"
                                  className="btn btn-primary btn-sm mt-2 me-2 mt-sm-0"
                                  onClick={(event) => {
                                    this.setState({
                                      updateStatus_modal: true
                                    });
                                  }}
                                >
                                  Update status
                                </button>
                                <button
                                  onClick={(event) =>
                                    this.CancelOrder(
                                      event,
                                      this.state.subData.id
                                    )
                                  }
                                  className="btn btn-primary btn-sm mt-2 mt-sm-0"
                                >
                                  Cancel Order
                                </button>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="profile-timeline">
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item eventKey={0}>
                                <Accordion.Header>
                                  Order Placed
                                  {/* {item.status_type === "location" ? 'Shipped' : 'Delivered'} (<Moment format="DD/MM/YYYY">{item.created_at}</Moment>) */}
                                </Accordion.Header>
                                <Accordion.Body>
                                  Order has been placed
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey={1}>
                                <Accordion.Header>
                                  Payment confirmed
                                  {/* {item.status_type === "location" ? 'Shipped' : 'Delivered'} (<Moment format="DD/MM/YYYY">{item.created_at}</Moment>) */}
                                </Accordion.Header>
                                <Accordion.Body>
                                  Payment has been confirmed
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey={2}>
                                <Accordion.Header>
                                  Purchase initiated
                                  {/* {item.status_type === "location" ? 'Shipped' : 'Delivered'} (<Moment format="DD/MM/YYYY">{item.created_at}</Moment>) */}
                                </Accordion.Header>
                                <Accordion.Body>
                                  Purchase has been initiated
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey={3}>
                                <Accordion.Header>
                                  Purchase completed
                                  {/* {item.status_type === "location" ? 'Shipped' : 'Delivered'} (<Moment format="DD/MM/YYYY">{item.created_at}</Moment>) */}
                                </Accordion.Header>
                                <Accordion.Body>
                                  Purchase has been completed
                                </Accordion.Body>
                              </Accordion.Item>
                              {statusHistory.map((item, index) => {
                                return (
                                  <Accordion.Item eventKey={index + 4}>
                                    <Accordion.Header>
                                      {item.status_type === 'location'
                                        ? 'Shipped'
                                        : 'Delivered'}{' '}
                                      (
                                      <Moment format="DD/MM/YYYY">
                                        {item.created_at}
                                      </Moment>
                                      )
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      {item.location}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                );
                              })}
                            </Accordion>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className="card mt-3 mb-3 h-auto">
                      <div className="card-header">
                        <div className="d-sm-flex align-items-center">
                          <h5 className="card-title flex-grow-1 mb-0">
                            Item Requirement
                          </h5>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="profile-timeline">
                          <Accordion defaultActiveKey="0">
                          {order_items.map((item, index) => {
                            let productJson = JSON.parse(item.productJson);
                            return (
                              <Accordion.Item eventKey={index + 1} key={index}>
                                <Accordion.Header>
                                  {productJson.product_name}
                                </Accordion.Header>
                                <Accordion.Body>
                                  {productJson.note_requirement}
                                </Accordion.Body>
                              </Accordion.Item>
                            )
                          })}
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 mr-3">
                    <div className="card mb-2 h-auto">
                      <div className="card-header">
                        <div className="d-flex">
                          <h5 className="card-title flex-grow-1 mb-0">
                            Customer Details
                          </h5>
                          <div className="flex-shrink-0">
                            <Link
                              to={`/super-admin-view-user/${btoa(customer.id)}`}
                              className="link-secondary"
                            >
                              View Profile
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <ul className="list-unstyled mb-0 vstack gap-3">
                          <li key={0}>
                            <div className="d-flex align-items-center mb-2">
                              <div className="flex-shrink-0 user-profile">
                                <img
                                  src={defaultImage}
                                  alt=""
                                  className="avatar-sm rounded avatar-img"
                                />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h6 className="fs-14 mb-1">
                                  {customer.full_name}
                                </h6>
                                <p className="text-muted mb-0 text-start">
                                  Customer
                                </p>
                              </div>
                            </div>
                          </li>

                          <li key={1}>
                            <i className="fa fa-envelope mr-2 align-middle text-muted fs-16"></i>
                            {customer.email}
                          </li>
                          <li key={2}>
                            <i className="fa fa-phone mr-2 align-middle text-muted fs-16"></i>
                            {customer.phone_no}
                          </li>
                        </ul>
                      </div>
                    </div>
                    {subData ? (
                      <div className="card mb-2 h-auto">
                        <div className="card-header">
                          <h5 className="card-title mb-0">
                            <i className="ri-map-pin-line align-middle mr-1 text-muted"></i>
                            Billing Address
                          </h5>
                        </div>
                        <div className="card-body">
                          <ul className="list-unstyled vstack gap-2 fs-13 mb-0">
                            <li key={0} className="fw-medium fs-14">
                              {subData.billing_first_name}{' '}
                              {subData.billing_last_name}
                            </li>
                            <li key={1}>{subData.billing_email}</li>
                            <li key={2}>{subData.billing_address}</li>
                            <li key={3}>
                              {subData.billing_state} ({subData.billing_zip})
                            </li>
                            <li key={4}>{subData.billing_country}</li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {/* {customer ? <div className="card mb-2">
                                            <div className="card-header">
                                                <h5 className="card-title mb-0"><i
                                                    className="ri-map-pin-line align-middle mr-1 text-muted"></i>
                                                    Shipping Address</h5>
                                            </div>
                                            <div className="card-body">
                                                <ul className="list-unstyled vstack gap-2 fs-13 mb-0">
                                                    <li className="fw-medium fs-14">{customer.shippingAddress.firstName} {customer.shippingAddress.lastName}</li>
                                                    <li>{customer.shippingAddress.phone_no}</li>
                                                    <li>{customer.shippingAddress.address}</li>
                                                    <li>{customer.shippingAddress.city}</li>
                                                    <li>{customer.shippingAddress.state},{customer.shippingAddress.country}</li>
                                                </ul>
                                            </div>
                                        </div> : ''} */}

                    {subData ? (
                      <div className="card mb-2 h-auto">
                        <div className="card-header">
                          <h5 className="card-title mb-0">
                            <i className="ri-secure-payment-line align-bottom mr-1 text-muted"></i>
                            Payment Details
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                              <p className="text-muted mb-0">Transactions:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">
                              <h6 className="mb-0">
                                #
                                {subData && subData.id
                                  ? subData.id
                                  : 'BHS4336345'}
                              </h6>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                              <p className="text-muted mb-0">Payment Method:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">
                              <h6 className="mb-0">
                                {subData.paymentMode
                                  ? subData.paymentMode
                                  : 'Debit card'}
                              </h6>
                            </div>
                          </div>

                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                              <p className="text-muted mb-0">Total Amount:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">
                              <h6 className="mb-0">${subData.totalprice}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </React.Fragment>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    loader: state.Auth.loader,
    error: state.Auth.error
  };
}
const mapDispatchToProps = {
  viewOrder: userActions.viewOrder,
  updateTrackingStatus: userActions.updateTrackingStatus,
  cancelOrder: userActions.cancelOrder
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(ViewOrder);
