import axios from "./../axios";
import jwt_decode from "jwt-decode";
import * as actionType from "../constants/actionTypes";

const paths = async (path, method, token, data, contentType) => {
  const url = import.meta.env.VITE_API_URL;
  return await axios({
    method: method,
    url: `${url}${path}`,
    data: data,
    headers: {
      authorization: "Bearer " + token,
      "Content-Type": `${contentType}`,
      "ngrok-skip-browser-warning": "true",
    },
  });
};

const apiPaths = {
  GenerateQrCode: "/generateQRCode",
};
/*** SignIn Action ***/
export const SignInStart = () => {
  return {
    error: null,
    loader: true,
    type: actionType.SignIn_START,
  };
};

export const SignInSuccess = (response) => {
  return {
    token: false,
    loader: false,
    type: actionType.SignIn_SUCCESS,
  };
};

export const SignInFail = (error) => {
  return {
    error: error,
    loader: false,
    type: actionType.SignIn_FAIL,
  };
};

export const SignIn = (form_data, check) => {
  return async (dispatch) => {
    dispatch(SignInStart()); // Dispatch action to indicate sign-in process has started

    try {
      // Make a POST request to the sign-in API endpoint
      const signInResponse = await axios.post(`/SignIn`, form_data);
      dispatch(SignInSuccess());
      // Check if sign-in was successful
      if (signInResponse.data.success) {
        // Dispatch action to indicate sign-in success
        dispatch(SignInSuccess("User login successfully !!!"));

        // Store user type and token based on form_data
        let userType = form_data.get("user_type");
        let authTokenKey = "";
        if (userType === "customer") {
          authTokenKey = "shiporfly_authtoken";
        } else if (userType === "super_admin") {
          authTokenKey = "shiporfly_super_admin_authtoken";
        } else {
          authTokenKey = "shiporfly_admin_authtoken";
        }
        localStorage.setItem("user_type", userType);
        localStorage.setItem("userId", signInResponse.data.userId);
        localStorage.setItem(authTokenKey, signInResponse.data.token);
        console.log("check", check);
        console.log("check", check);
        if (userType === "super_admin") {
          window.location.href = "/super-admin-dashboard";
        } else if (check) {
          window.location.href = "/currency-rate";
        } else {
          // window.location.href = "/";
        }

        return signInResponse.data;
      } else {
        dispatch(SignInFail(signInResponse.data.message));
        return signInResponse.data;
        // Dispatch action to indicate sign-in failure
      }
    } catch (error) {
      dispatch(SignInFail());
      // Dispatch action to indicate sign-in failure
      dispatch(SignInFail("Something went wrong, Please try again."));
    }
  };
};

export const getGenerateQrCode = (tokenData, email) => {
  // let contentType = 'application/json';
  const endpoint = `${apiPaths.GenerateQrCode}/${email}`;

  let response = paths(endpoint, "post", tokenData, null, null);
  return response;
};

/*** SocialSignIn Action ***/
export const SocialSignInStart = () => {
  return {
    error: null,
    loader: true,
    type: actionType.SocialSignIn_START,
  };
};

export const SocialSignInSuccess = (response) => {
  return {
    token: false,
    loader: false,
    type: actionType.SocialSignIn_SUCCESS,
  };
};

export const SocialSignInFail = (error) => {
  return {
    error: error,
    loader: false,
    type: actionType.SocialSignIn_FAIL,
  };
};

export const SocialSignIn = (form_data) => {
  return (dispatch) => {
    dispatch(SocialSignInStart());
    return axios({
      method: "post",
      data: form_data,
      headers: { "Content-Type": "application/json" },
      url: `${actionType.environment_url}/SocialSignIn`,
    })
      .then(function (response) {
        dispatch(SocialSignInSuccess(response.data.token));
        localStorage.setItem("shiporfly_authtoken", response.data.token);
        localStorage.setItem("userId", response.data.userId);
        return response.data;
      })
      .catch(function (error) {
        dispatch(SocialSignInFail("Something went wrong, Please try again."));
        return error;
      });
  };
};

/*** Get Auth Userdata Action ***/
export const GetAuthUserStart = () => {
  return {
    error: null,
    loader: false,
    type: actionType.GetAuthUser_START,
  };
};

export const GetAuthUserSuccess = (idToken) => {
  return {
    token: false,
    loader: idToken,
    type: actionType.GetAuthUser_SUCCESS,
  };
};

export const GetAuthUserFail = (error) => {
  console.log(error);
  return {
    error: error,
    loader: false,
    type: actionType.GetAuthUser_FAIL,
  };
};

export const GetAuthUser = () => {
  return (dispatch) => {
    dispatch(GetAuthUserStart());
    return axios({
      method: "get",
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${actionType.environment_url}/GetAuthUser`,
    })
      .then(function (response) {
        if (response.data.success === true) {
          localStorage.setItem(
            "shiporfly_authuser",
            JSON.stringify(response.data.user_info)
          );
        } else {
          localStorage.removeItem("shiporfly_authuser");
        }
        dispatch(GetAuthUserSuccess("getAuthUser successfully !!!"));
        return response.data;
      })
      .catch(function (error) {
        dispatch(GetAuthUserFail("Something went wrong, Please try again."));
        return error;
      });
  };
};

export const GetAuthUser1 = () => {
  return (dispatch) => {
    dispatch(GetAuthUserStart());
    return axios({
      method: "get",
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${actionType.environment_url}/GetAuthUser`,
    })
      .then(function (response) {
        if (response.data.success === true) {
          localStorage.setItem(
            "shiporfly_authuser",
            JSON.stringify(response.data.user_info)
          );
        } else {
          localStorage.removeItem("shiporfly_authuser");
        }
        dispatch(GetAuthUserSuccess("getAuthUser successfully !!!"));
        return response.data;
      })
      .catch(function (error) {
        dispatch(GetAuthUserFail("Something went wrong, Please try again."));
        return error;
      });
  };
};

export const isAuthorize = () => {
  const user_type = localStorage.getItem("user_type");
  const token = localStorage.getItem("shiporfly_authtoken");
  console.log(user_type);
  if (!token) {
    return false;
  }
  try {
    const decodedToken = jwt_decode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      return false;
    } else {
      return true;
    }
  } catch (err) {
    return false;
  }
  // const shiporfly_authuser = localStorage.getItem("shiporfly_authuser");
  // const shiporfly_authtoken = localStorage.getItem("shiporfly_authtoken");
  // let decoded = jwt.verify(token, process.env.shiporfly_authtoken);
  // return shiporfly_authuser && shiporfly_authtoken ? true : false;
};

/*** SignUp Action ***/
export const SignUpStart = () => {
  return {
    error: null,
    loader: true,
    type: actionType.SignUp_START,
  };
};

export const SignUpSuccess = (response) => {
  return {
    token: false,
    loader: false,
    type: actionType.SignUp_SUCCESS,
  };
};

export const SignUpFail = (error) => {
  return {
    error: error,
    loader: false,
    type: actionType.SignUp_FAIL,
  };
};

// export const SignUp = (form_data) => {
//     return (dispatch) => {
//         dispatch(SignUpStart());

//         return axios({
//             method: "post",
//             data: form_data,
//             url: `${actionType.environment_url}/SignUp`,
//         })
//             .then(function (response) {
//                 dispatch(SignUpSuccess(response.data));
//                 return response.data;
//             })
//             .catch(function (error) {
//                 dispatch(SignUpFail("Something went wrong, Please try again."));
//                 return error;
//             });
//     };
// };

export const SignUp = (form_data) => {
  // Static phone number
  const phoneNumber = "1234567890";

  // Combine static phone number with other form data
  const dataToSend = {
    ...form_data,
    phone_no: phoneNumber,
  };

  return (dispatch) => {
    dispatch(SignUpStart());

    return axios({
      method: "post",
      data: dataToSend,
      url: `${actionType.environment_url}/SignUp`,
    })
      .then(function (response) {
        dispatch(SignUpSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(SignUpFail("Something went wrong, Please try again."));
        return error;
      });
  };
};

/*** SocialSignUp Action ***/
export const SocialSignUpStart = () => {
  return {
    error: null,
    loader: true,
    type: actionType.SocialSignUp_START,
  };
};

export const SocialSignUpSuccess = (response) => {
  return {
    token: false,
    loader: false,
    type: actionType.SocialSignUp_SUCCESS,
  };
};

export const SocialSignUpFail = (error) => {
  return {
    error: error,
    loader: false,
    type: actionType.SocialSignUp_FAIL,
  };
};

export const SocialSignUp = (form_data) => {
  return (dispatch) => {
    dispatch(SocialSignUpStart());
    return axios({
      method: "post",
      data: form_data,
      url: `${actionType.environment_url}/SocialSignUp`,
    })
      .then(function (response) {
        dispatch(SocialSignUpSuccess(response.data.token));
        localStorage.setItem("shiporfly_authtoken", response.data.token);
        localStorage.setItem("userId", response.data.userId);
        return response.data;
      })
      .catch(function (error) {
        dispatch(SocialSignUpFail("Something went wrong, Please try again."));
        return error;
      });
  };
};

/*** ForgotPassword Action ***/
export const ForgotPasswordStart = () => {
  return {
    error: null,
    loader: true,
    type: actionType.ForgotPassword_START,
  };
};

export const ForgotPasswordSuccess = (response) => {
  return {
    token: false,
    loader: false,
    type: actionType.ForgotPassword_SUCCESS,
  };
};

export const ForgotPasswordFail = (error) => {
  return {
    error: error,
    loader: false,
    type: actionType.ForgotPassword_FAIL,
  };
};

export const ForgotPassword = (form_data) => {
  return (dispatch) => {
    dispatch(ForgotPasswordStart());

    return axios({
      method: "post",
      data: form_data,
      headers: { "Content-Type": "application/json" },
      url: `${actionType.environment_url}/ForgotPassword`,
    })
      .then(function (response) {
        dispatch(ForgotPasswordSuccess(response.data.token));
        localStorage.setItem("shiporfly_forgotemail", form_data.email);
        return response.data;
      })
      .catch(function (error) {
        dispatch(ForgotPasswordFail("Something went wrong, Please try again."));
        return error;
      });
  };
};

/*** ResetPassword Action ***/
export const ResetPasswordStart = () => {
  return {
    error: null,
    loader: true,
    type: actionType.ResetPassword_START,
  };
};

export const ResetPasswordSuccess = (response) => {
  return {
    token: false,
    loader: false,
    type: actionType.ResetPassword_SUCCESS,
  };
};

export const ResetPasswordFail = (error) => {
  return {
    error: error,
    loader: false,
    type: actionType.ResetPassword_FAIL,
  };
};

export const ResetPassword = (form_data) => {
  return (dispatch) => {
    dispatch(ResetPasswordStart());
    return axios({
      method: "post",
      data: form_data,
      headers: { "Content-Type": "application/json" },
      url: `${actionType.environment_url}/ResetPassword`,
    })
      .then(function (response) {
        dispatch(ResetPasswordSuccess(response.data));
        localStorage.setItem("shiporfly_authtoken", response.data.token);
        localStorage.setItem("userId", response.data.userId);
        return response.data;
      })
      .catch(function (error) {
        dispatch(ResetPasswordFail("Something went wrong, Please try again."));
        return error;
      });
  };
};

/*** Logout Action ***/
export const Logout = () => {
  return axios({
    method: "post",
    url: `${actionType.environment_url}/Logout`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
    },
  })
    .then(function (response) {
      if (response.data.Success === true) {
        localStorage.removeItem("shiporfly_authuser");
        localStorage.removeItem("shiporfly_authtoken");
      }
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
};
