import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import * as userActions from '../../../actions/account';
import * as cartActions from '../../../actions';
import * as checkoutActions from '../../../actions/checkout';
import Loader from '../../../components/Utility/Loader';
import LoginHeader from '../../../components/LoginHeader';
import LoginFooter from '../../../components/LoginFooter';
import getSymbolFromCurrency from 'currency-symbol-map';
import { confirmAlert } from 'react-confirm-alert';
import { ServiceCharge } from '../../Utility/Charges';
import 'react-confirm-alert/src/react-confirm-alert.css';

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      total: null,
      quantity: null,
      subtotal: null,
      buttonDIsable: false,
      columns: null,
      cartItemCount: null,
      totalQuantity: null,
      currencyRate: null,
      loading: true,
      ApiCurrency: getSymbolFromCurrency('RMB'),
      ConversionCurrency: getSymbolFromCurrency('NGN'),
      whishListItemCount: null,
      shippingMods: [],
      productItem: this.props.productItem,
      serviceCharge: 0,
      initialPercentage: 10,
      total: 0
    };
    this.elementRef = createRef();
    this.DecreaseItem = this.DecreaseItem.bind(this);
    this.IncrementItem = this.IncrementItem.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.Checkout = this.Checkout.bind(this);
  }

  componentDidMount() {
    // window.scrollTo(0, 0);
    this.props.onCart().then((response) => {
      this.setState({
        cartItemCount: response.data?.length,
        products: response.data,
        subtotal: parseFloat(
          response?.data
            ?.reduce(
              (total, item) =>
                total +
                item.price *
                  item.quantity *
                  localStorage.getItem('currencyRate'),
              0
            )
            ?.toFixed(2)
        ),
        totalQuantity: response?.data?.reduce(
          (total, item) => total + item.quantity,
          0
        ),
        currencyRate: localStorage.getItem('currencyRate'),
        loading: false // Set loading state to false after data retrieval
      });
    });

    this.props.onWishlist().then((response) => {
      if (!response?.message == 'Unauthorized') {
        this.setState({
          user_info: false
        });
      } else {
        this.setState({
          whishListItemCount: response?.length,
          user_info: response?.user_info
        });
      }
    });

    const columns = [
      {
        name: 'Image',
        cell: (row) => (
          <Link
            href={`/product-detail/search?${row.external_id}`}
            state={{ product_detail: row }}
          >
            {' '}
            <img src={row.product_avatar} style={{ width: '150px' }} />
          </Link>
        ),
        width: '200px'
      },
      {
        name: 'Title',
        cell: (row) => (
          <a href={`/product-detail/search?${row.external_id}`}>
            <span style={{ padding: '10px' }}>
              {row.product_name}
              {/* <TranslateComponent
                content={row.product_name}
                targetLanguage="en"
              /> */}
            </span>
          </a>
        ),
        sortable: true,
        width: '300px',
        wrap: true
      },
      {
        name: 'Price',
        cell: (row) => (
          <div class="price">
            <span>{this.state.ConversionCurrency}</span>

            <span>
              {parseFloat(row.price * this.state.currencyRate).toLocaleString(
                undefined,
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}
            </span>
          </div>
        ),
        sortable: true,
        width: '150px'
      },
      {
        name: 'Quantity',
        cell: (row) => (
          <div class="qty-container">
            <button
              class="qty-btn-minus btn-light"
              type="button"
              onClick={() => this.DecreaseItem(row.quantity, row.id)}
            >
              <i class="fa fa-minus"></i>
            </button>
            <input
              type="text"
              name="qty"
              min={1}
              max={9}
              value={
                row.quantity === 0 ? this.deleteProduct(row.id) : row.quantity
              }
              class="input-qty"
            />
            <button
              class="qty-btn-plus btn-light"
              type="button"
              onClick={() => this.IncrementItem(row.quantity, row.id)}
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
        ),
        width: '150px'
      },
      // {
      //     name: 'Note requirement',
      //     cell: row =><span class="requirement">{row.note_requirement}</span>,
      //     width: "250px",
      //     wrap: true
      // },
      {
        name: 'Total',
        cell: (row) => (
          <div class="total-price">
            <span>{this.state.ConversionCurrency}</span>
            <span>
              {parseFloat(
                row.price * row.quantity * this.state.currencyRate
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </span>
          </div>
        ),
        width: '150px'
      },
      {
        name: '',
        cell: (row) => (
          <i class="fa fa-trash" onClick={() => this.deleteProduct(row.id)}></i>
        ),
        width: '80px'
      }
    ];

    this.setState({
      columns: columns
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.subtotal !== this.state.subtotal) {
      console.log('Subtotal updated:', this.state.subtotal, prevState.subtotal);
      this.CalculateServiceCharge();
    }
  }

  DecreaseItem = (quantity, cartItemId) => {
    if (quantity > 1) {
      let updateQty = quantity - 1;
      const form_data = new FormData();
      form_data.append('quantity', updateQty);
      form_data.append('cartItemId', cartItemId);
      this.props.onQty(form_data).then((response) => {
        this.componentDidMount();
      });
    } else {
      this.deleteProduct(cartItemId);
    }
  };

  IncrementItem = (quantity, cartItemId) => {
    let updateQty = parseInt(quantity) + 1;
    const form_data = new FormData();
    form_data.append('quantity', updateQty);
    form_data.append('cartItemId', cartItemId);
    this.props.onQty(form_data).then((response) => {
      this.componentDidMount();
    });
  };

  deleteProduct = (cartId) => {
    confirmAlert({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this product?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.onRemove(cartId).then((response) => {
              if (response.success === true) {
                this.componentDidMount();
                toast.success(response.message, {
                  position: toast.POSITION.TOP_RIGHT
                });
              } else {
                toast.error(response.message, {
                  position: toast.POSITION.TOP_RIGHT
                });
              }
            });
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  Checkout = () => {
    this.setState({ buttonDIsable: true });
    const form_data = new FormData();

    form_data.append('price', this.state.subtotal);
    this.props.onCheckout(form_data).then((response) => {
      if (response.success === true) {
        this.componentWillMount();
        toast.success(response.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    });
  };

  CalculateServiceCharge = () => {
    const { subtotal, totalQuantity } = this.state;
    ServiceCharge({
      price: subtotal,
      qty: totalQuantity
    }).then((result) => {
      console.log("Chcek datatyope",result.serviceCharge,  subtotal)
      let charge = result.serviceCharge + subtotal
      this.setState({
        serviceCharge: result.serviceCharge.toFixed(2),
        total: charge.toFixed(2)
      });
    });
  };

  render() {
    const {
      products,
      subtotal,
      serviceCharge,
      columns,
      cartItemCount,
      whishListItemCount,
      CurrencySymbol,
      currencyRate,
      ConversionCurrency,
      total,
      totalQuantity
    } = this.state;
    const { productItem } = this.props;

    if (this.state.loading) {
      return <Loader />; // Render loading state if data is still loading
    }
    return (
      <React.Fragment>
        {this.state.loader ? <Loader /> : null}
        {this.props.loader ? <Loader /> : null}
        <div className="">
          <div className="checkout-template">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="title">
                    <h1 className="d-flex">Cart</h1>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="item-list">
                    {products?.length > 0 && (
                      <>
                        <DataTable
                          pagination
                          columns={columns}
                          data={products}
                        />
                        <div className="total-items-section">
                          <div className="total-items">
                            <div className="total-value">
                              <label>
                                Subtotal:
                                <span>
                                  {ConversionCurrency}
                                  {parseFloat(subtotal).toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    }
                                  )}
                                </span>
                              </label>
                            </div>
                            <div className="total-value">
                              <label>
                                Service Charge:
                                <span>
                                  {ConversionCurrency}
				{parseFloat(serviceCharge).toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    }
                                  )}
                                
                                </span>
                              </label>
                            </div>
                            <div className="total-value">
                              <label>
                                Total:
                                <span>
                                  {ConversionCurrency}
                                  {parseFloat(total).toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    }
                                  )}
                                </span>
                              </label>
                            </div>
                            {/* <div className="total-value grand-total">
                              <label>
                                Grand Total:
                                <span>
                                  {ConversionCurrency}
                                  {parseFloat(subtotal).toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    }
                                  )}
                                </span>
                              </label>
                            </div> */}
                          </div>
                          <div className="shiping-info">
                            <h6> 
			    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            </h6>
                            <Link
  disabled={!this.state.buttonDIsable}
  to={{ pathname: '/checkout' }}
  className="checkout-btn"
>
  <i className="fa-solid fa-money-check me-2"></i> Checkout
</Link>
                          </div>
                        </div>
                      </>
                    )}
                    {products?.length === 0 && (
                      <div className="empty-cart">
                        <span>Your Cart is Empty</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    loader: state.Auth.loader,
    error: state.Auth.error
  };
}
const mapDispatchToProps = {
  onCart: cartActions.GetCart,
  onQty: cartActions.CartQtyUpdate,
  onWishlist: userActions.GetWishlist,
  onRemove: cartActions.RemoveProduct,
  onCheckout: checkoutActions.CheckOutCreate
};
export default connect(mapStateToProps, mapDispatchToProps)(Cart);
