import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

const SignIn = () => {
  const { loginWithRedirect, isLoading, error, isAuthenticated } = useAuth0();

  // return (
  //     <div>
  //         {isLoading ? <Loader /> : null}
  //     </div>
  // );

  return (
    <Link
      onClick={() => {
        loginWithRedirect();
      }}
      className="sign-in"
      style={{ color: "#fff" }}
    >
      <img src="/assets/images/login.png" alt="Sign-in" />
      Sign In
    </Link>
  );
};

export default SignIn;
